import axios from 'axios'
import store from '../store/index'

axios.interceptors.request.use ((config) => {
  if (store.getters.isUser && (config.auth !== false)) {
    config.headers['Authorization'] = 'Bearer ' + store.state.auth.access_token
  }
  return config
})

axios.interceptors.response.use((response) => {
  return response
}, async (error) => {
  if (error.response && error.response.status === 401 && !error.config.tokenRefreshDisabled) {
    return await store.dispatch('auth.refresh')
      .then(() => {
        error.config.headers['Authorization'] = 'Bearer ' + store.state.auth.access_token
        return axios(error.config)
      })
  }

  return Promise.reject(error);
})

const fileDownload = (url, name) => {
  return axios.get(url, {
    responseType: 'blob'
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    link.click();
  })
}

/**
 * Default options
 * @type {{client: *, url: string}}
 */
const defaultOptions = {
  /**
   * Basic url
   * По умолчанию используем урл для локалки
   * @default: https://api-app-test.altpay.uk
   */
  url: 'api-app-test.altpay.uk',

  /**
   * List of mapped urls for backend url
   */
  hosts: [
    // Production subdomains
    {
      source: 'app.altpay.uk',
      target: 'api.altpay.uk',
    },
    {
      source: 'app.akaruy.com',
      target: 'api.akaruy.com'
    },
    // {
    //   source: 'app.bankbee.eu',
    //   target: 'api-app.bankbee.eu'
    // },
    // {
    //   source: 'app.dmpay.biz',
    //   target: 'api-app.dmpay.biz'
    // },
    {
      source: 'app-stage.altpay.uk',
      target: 'api-app.stage.altpay.uk'
    },
    {
      source: 'app.stage.altpay.uk',
      target: 'api.stage.altpay.uk'
    },

    // Test subdomains
    {
      source: 'app-test.altpay.uk',
      target: 'api-app-test.altpay.uk'
    },
    {
      source: 'app-test.akaruy.com',
      target: 'api-app-test.akaruy.com'
    },
    // {
    //   source: 'app-test-bankbee.altpay.uk',
    //   target: 'api-app-test-bankbee.altpay.uk'
    // },
    // {
    //   source: 'app-test-dmpay.altpay.uk',
    //   target: 'api-app-test-dmpay.altpay.uk'
    // }
  ],

  /**
   * Detect the target domain that will be used as sdk url
   * @return {*|string}
   */
  detect () {
    const my_host = document.location.host
    const my_port = document.location.protocol
    const backend = this.hosts.find(i => {
      return i.source === my_host
    })

    return my_port + '//' + (backend ? backend.target : this.url)
  }
}

export default {
  /**
   * Install our plugin
   *
   * @param Vue
   * @param settings
   */
  install: function (Vue, settings) {
    const options = { ...defaultOptions, ...settings }

    axios.defaults.baseURL = options.detect()

    Vue.sdk = Vue.prototype.$sdk = {
      options: options,

      /**
       * Get list of available countries for creating beneficiary
       *
       * @param params
       * @returns {Promise<AxiosResponse<T>>}
       */
      getCountries: function (params) {
        return axios.get('/v1/country', {
          params: params,
          auth: false
        })
      },

      wallet: {
        /**
         * Create new wallet
         */
        create: function (data = {}, config = {}) {
          return axios.post(`/v1/wallet`, data, config)
        },

        /**
         * Update record
         *
         * @param id
         * @param data
         * @param config
         * @return {Promise<AxiosResponse<any>>}
         */
        update: function (id, data, config = {}) {
          return axios.patch(`/v1/wallet/${id}`, data, config)
        },

        /**
         * Get wallet by ID
         */
        show: function (wallet_id, expand = ['currency', 'transferSourceWalletId']) {
          let params = {}

          if (Array.isArray(expand) && expand.length > 0) {
            params.expand = expand.join(',')
          }

          return axios.get(`/v1/wallet/${wallet_id}`, {
            params: params,
          })
        },

        /**
         * Get all balances
         *
         * @property {number} params.page
         * @property {string} params.search
         * @property {number} params.per-page
         * @property params.expand
         */
        list: function (params = {}) {
          let defaultParams = {
            'expand': ['currency', 'transferSourceWalletId'].join(',')
          }

          return axios.get('/v1/wallet', {
            params: {...defaultParams, ...params},
          })
        },

        /**
         * Return list of payments
         *
         * @param id
         * @param params
         * @param expand
         * @return {Promise<AxiosResponse<any>>}
         */
        payments (id, params, expand = []) {
          let query = {
            ...params
          }

          if (Array.isArray(expand) && expand.length > 0) {
            query.expand = expand.join(',')
          }

          return axios.get(`/v1/wallet/${id}/payment`, {
            params: query
          })
        },

        /**
         * @param wallet_id
         * @param data
         * @param validate
         * @return {Promise<AxiosResponse<any>>}
         */
        topUp (wallet_id, data, validate = 1) {
          return axios.post(`/v1/wallet/${wallet_id}/top-up`, data, {
            params: { validate }
          })
        },

        /**
         * @param wallet_id
         * @param data
         * @param validate
         * @return {Promise<AxiosResponse<any>>}
         */
        topUpByApplePay (wallet_id, data, validate = 1) {
          return axios.post(`/v1/wallet/${wallet_id}/top-up/apple`, data, {
            params: { validate }
          })
        },

        topUpByKlarna (wallet_id, data, validate = 1) {
          return axios.post(`/v1/wallet/${wallet_id}/top-up/klarna`, data, {
            params: { validate }
          })
        },

        /**
         * Calc fee for wallet top up
         *
         * @param wallet_id
         * @param data
         * @return {Promise<AxiosResponse<T>>}
         */
        getTopUpFee: function (wallet_id, data) {
          return axios.post(`/v1/wallet/${wallet_id}/top-up/fee`, data)
        },

        /**
         * Report
         */
        report: {
          create (wallet_id, format, params) {
            return axios.get(`/v1/wallet/${wallet_id}/payment.${format}`, {
              params: params
            })
          },

          /**
           * Download action for url
           *
           * @param url
           */
          download (url) {
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('target', '_blank');
            link.click();
          }
        }
      },

      /**
       * Payment
       */
      payment: {
        /**
         * Return list of available payments
         *
         * @param params
         */
        list: function (params = {}) {
          params.expand = ['merchant', 'currencyExchange'].join(',')
          return axios.get('/v1/payment', {
            params: params
          })
        },

        /**
         * Return payment details
         *
         * @param id - Payment ID
         * @param params
         */
        show: function (id, params = {}) {
          params.expand = ['currency', 'merchant', 'currencyExchange', 'files'].join(',')
          return axios.get(`/v1/payment/${id}`, {
            params: params
          })
        },

        /**
         * Calc fee for payment
         *
         * @params:
         *  wallet_id - integer
         *  beneficiary_id - integer
         *  amount - float
         *
         * @param params
         * @returns {Promise<AxiosResponse<T>>}
         */
        getPaymentFee: function (params) {
          return axios.get('/v1/payment/fee', {
            params: params
          })
        },

        /**
         * Get max amount to display all button
         *
         * @param data
         * @param config
         * @return {Promise<AxiosResponse<any>>}
         */
        getMaxAmount: function (params) {
          return axios.get('/v1/payment/max-amount', {
            params: params
          })
        },

        /**
         * Create new payment
         *
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<T>>}
         */
        create: function (data, validate = 1) {
          return axios.post('/v1/payment', data, {
            params: {
              validate: validate ?? undefined
            }
          })
        },

        /**
         * Sign payment
         * Send id and code
         *
         * @param data
         * @param validate
         * @return {Promise<AxiosResponse<T>>}
         */
        sign: function (data, validate = 1) {
          return axios.post('/v1/payment/sign', data, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Delete draft payment
         *
         * @param {Object} config
         * @param {Object} config.data
         * @param {Array} config.data.id - array of payments id numbers to delete
         * @returns {Promise<AxiosResponse<any>>}
         */
        delete: function (config = {}) {
          return axios.delete('/v2/payment', config)
        },

        /**
         * Upload files for new payment
         *
         * @param data
         * @param config
         * @returns {Promise<AxiosResponse<any>>}
         */
        upload: function (data, config) {
          return axios.post('/v1/payment/upload', data, config)
        },

        /**
         * Cancel payment
         *
         * @param id - Payment ID
         * @param data
         * @returns {Promise<AxiosResponse<T>>}
         */
        cancel: function (id, data = {}) {
          return axios.post(`/v1/payment/${id}/cancel`, data)
        },

        /**
         * Download payment confirmation
         *
         * @param id
         * @param type
         * @return {Promise<AxiosResponse<any>>}
         */
        getConfirmation(id, type = 'pdf') {
          return fileDownload(`/v1/payment/${id}/download/confirmation`, `payment_confirmation_${id}.${type}`)
        },

        /**
         * Create link to download file and click it
         * payment.file object as param
         *
         * @param url
         * @param name
         * @return {Promise<AxiosResponse<any>>}
         */
        downloadFile({url, name}) {
          return fileDownload(url, name)
        }
      },

      exchange: {
        /**
         * Create currency exchange
         *
         * @param data
         * @param config
         * @return {Promise<AxiosResponse<any>>}
         */
        create (data, config) {
          return axios.post('/v1/currency-exchange', data, config)
        },

        /**
         * Calculate fee of conversion
         *
         * @param params
         * @return {Promise<AxiosResponse<any>>}
         */
        getFee (params) {
          return axios.get('/v1/currency-exchange/fee', {
            params: params
          })
        },

        /**
         * Get max amount to display all button
         *
         * @param params
         * @returns {*}
         */
        getMaxAmount: function (params) {
          return axios.get('/v1/currency-exchange/max-amount', {
            params: params
          })
        },

        /**
         * Intended exchange result
         *
         * @param params
         * @return {*}
         */
        result (params) {
          return axios.get('/v1/currency-exchange/result', {
            params: params
          })
        }
      },

      /**
       * Beneficiary
       */
      beneficiary: {
        /**
         * Get list of beneficiaries
         *
         * @param params
         * @returns {Promise<AxiosResponse<any>>}
         */
        list: function (params) {
          return axios.get('/v1/beneficiary', {
            params: params
          })
        },

        /**
         * Get one beneficiary by id
         *
         * @param id
         * @return {Promise<AxiosResponse<any>>}
         */
        find: function (id, expand = []) {
          return axios.get(`/v1/beneficiary/${id}`, {
            params: {
              expand: expand.join(',')
            }
          })
        },

        /**
         * Create beneficiary
         * @param data
         * @param config
         * @returns {Promise<AxiosResponse<any>>}
         */
        create: function (data, config) {
          return axios.post('/v2/beneficiary', data, config)
        },

        /**
         * Delete beneficiary
         *
         * @return {Promise<AxiosResponse<T>>}
         * @param id
         * @param config
         */
        delete: function (id, config = {}) {
          return axios.delete(`/v1/beneficiary/${id}`, config)
        },

        /**
         * Get list of available countries for creating beneficiary
         *
         * @param params
         * @returns {Promise<AxiosResponse<T>>}
         */
        getCurrencies: function (params) {
          return axios.get('/v1/beneficiary/currency-list', {
            params: params
          })
        },

        /**
         * Return basic information and flags about IBAN
         *
         * @param iban
         * @param config
         * @returns {Promise<AxiosResponse<any>>}
         */
        getIbanInfo (iban, config) {
          return axios.get(`/v1/beneficiary/iban-info/${iban}`, config)
        }
      },

      /**
       * Settings
       */
      settings: {
        /**
         * Get secret code and image url
         *
         * @returns {Promise<AxiosResponse<T>>}
         */
        getMfaData: function () {
          return axios.get('/v1/mfa')
        },

        /**
         * Enable\Disable mfa data
         *
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<T>>}
         */
        switchMfaData: function (data, validate = 1) {
          return axios.post('/v1/mfa', data, {
            params: {
              validate: validate ?? undefined
            }
          })
        },

        /**
         * Update notifications list
         *
         * @param data
         * @param config
         * @returns {Promise<AxiosResponse<T>>}
         */
        updateNotifications: function (data, config = {}) {
          return axios.patch('/v1/notification', data, config)
        }
      },

      /**
       * Wishlist
       */
      wishlist: {
        /**
         * List of available options
         * @return {Promise<AxiosResponse<any>>}
         */
        list () {
          return axios.get('/v1/wish-list')
        },

        /**
         * Update wishlist
         * @param card
         * @param investment_account
         * @return {Promise<AxiosResponse<any>>}
         */
        update ({card, investment_account}) {
          return axios.patch('/v1/wish-list', {
            card: card,
            investment_account: investment_account
          })
        }
      },

      /**
       * Notifications
       */
      notifications: {

        /**
         * Return list of notifications
         *
         * @param params
         * @returns {Promise<AxiosResponse<T>>}
         */
        list: function (params) {
          return axios.get('/v2/notification', {
            params: params
          })
        },

        /**
         * Mark notification as read
         *
         * @param id
         * @returns {Promise<AxiosResponse<T>>}
         */
        markAsRead: function (id) {
          return axios.patch(`/v2/notification/${id}`)
        }
      },

      /**
       * User auth logic
       */
      auth: {
        /**
         * Login user
         *
         * @param {object} data
         * @param {boolean|number} validate
         * @param {string} data.fingerprint
         * @param {string} data.username
         * @param {string} data.password
         * @param {string} data.code
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        login: function (data, validate = true) {
          return axios.get('/v1/auth/token', {
            params: {
              fingerprint_id: 'web.' + data.fingerprint,
              code: data.code,
              validate: Number(validate)
            },
            auth: {
              username: data.username,
              password: data.password,
            },
            tokenRefreshDisabled: true
          })
        },

        /**
         * Return new access_token and refresh_token
         *
         * @return {Promise<AxiosResponse<any>>}
         * @param token
         * @param config
         */
        refresh: function (token) {
          return axios.post('/v1/auth/refresh', {token: token}, {auth: false})
        },

        /**
         * Change password
         *
         * @param data
         * @param config
         * @return {Promise<AxiosResponse<T>>}
         */
        changePassword: function (data, config) {
          return axios.post('/v1/auth/change-password', data, config)
        },

        /**
         * @returns {Promise<AxiosResponse<T>>}
         */
        requestResetPassword: function (data, config) {
          return axios.post('/v1/auth/reset-password', data, config)
        },

        /**
         * @returns {Promise<AxiosResponse<T>>}
         */
        requestResetPasswordToken: function (token, data, config) {
          return axios.put(`/v1/auth/reset-password/${token}`, data, config)
        }
      },

      /**
       * Get user information
       */
      user: {
        /**
         *
         * @param params
         * @returns {Promise<AxiosResponse<any>>}
         */
        info: function (params) {
          return axios.get('/v1/user', {
            params: params
          })
        },

        /**
         * Update personal data
         *
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<T>>}
         */
        update: function (data = {}, validate = 1) {
          return axios.patch('/v2/user', data, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Delete user
         *
         * @param config
         * @return {Promise<AxiosResponse<T>>}
         */
        delete: function (config = {}) {
          return axios.delete('/v1/user', config)
        },

      },

      /**
       * Get company information
       */
      company: {
        /**
         *
         * @param params
         * @returns {Promise<AxiosResponse<any>>}
         */
        info: function (params) {
          return axios.get('/v1/company', {
            params: params
          })
        },

        /**
         * Delete user
         *
         * @param config
         * @return {Promise<AxiosResponse<T>>}
         */
        delete: function (config = {}) {
          return axios.delete('/v1/company', config)
        },
      },



      /**
       * Management child users
       */
      management: {

        /**
         * Invite users
         *
         * @returns {Promise<AxiosResponse<T>>}
         * @param form
         * @param config
         */
        invite: function (form, config) {
          return axios.post('/v1/member', form, config)
        },

        /**
         * List of child users
         *
         * @param params
         * @returns {Promise<AxiosResponse<T>>}
         */
        list: function (params = {}) {
          return axios.get('/v1/member', { params })
        },

        /**
         * Show beneficiary details
         *
         * @returns {Promise<AxiosResponse<T>>}
         * @param id
         * @param config
         */
        view: function (id, config = {}) {
          return axios.get(`/v1/member/${id}`, config)
        },

        /**
         * Delete user
         *
         * @param id
         * @param config
         */
        delete: function (id, config = {}) {
          return axios.delete(`/v1/member/${id}`, config)
        },

        /**
         * Update user
         *
         * @param id
         * @param form
         * @param config
         * @returns {Promise<AxiosResponse<T>>}
         */
        update: function (id, form, config = {}) {
          return axios.patch(`/v1/member/${id}`, form, config)
        },

        /**
         * Return list of available user roles
         *
         * @param params
         * @returns {Promise<AxiosResponse<T>>}
         */
        roles: function (params) {
          return axios.get('/v1/member/roles', params)
        }
      },

      /**
       * Cards
       */
      card: {
        /**
         * Return information about delivery ways
         *
         * @return {Promise<AxiosResponse<any>>}
         */
        getInfo () {
          return axios.get('/v1/card/info')
        },

        getCardInfoPrice (params = {}) {
          return axios.get('/v1/card/info/price', {
            params: params,
          })
        },

        /**
         * Return list of cards
         *
         * @param {Number} params.page
         * @param {Number} params.per-page
         * @param {Number} params.wallet_id
         * @param {String} params.group
         */
        list (params ) {
          return axios.get('/v1/card', {
            params: params
          })
        },

        /**
         * Create new card
         *
         * @param {Object} data
         * @param {Number} data.wallet_id
         * @param {Number} data.fee_wallet_id
         * @param {String} data.type
         * @param {String} data.first_name
         * @param {String} data.last_name
         * @param {String} data.street
         * @param {String} data.house
         * @param {String} data.city
         * @param {String} data.country_id iso2 code of country list
         * @param {String} data.postal_code
         * @param {String} data.phone
         * @param {String} data.password 3d-secure password
         * @param {String} data.delivery_type
         * @param {String} data.code
         *
         * @param {Number} validate
         */
        create (data = {}, validate = 1) {
          return axios.post('/v1/card', data, {
            params: {
              validate: validate ?? undefined
            }
          })
        },

        /**
         * Update information about card
         *
         * @param {Number} id
         * @param {Number} validate
         *
         * @param {Object} data
         * @param {String} data.status
         * @param {String} data.password
         * @param {String} data.pin
         * @param {String} data.code
         */
        update (id, data = {}, validate = 1) {
          return axios.patch(`/v1/card/${id}`, data, {
            params: {
              validate: validate ?? undefined
            }
          })
        },

        /**
         * Get information about card
         *
         * @param {Number} id
         * @param {Array} expand
         * @param code
         */
        info (id, expand = [], code) {
          return axios.get(`/v1/card/${id}`, {
            params: {
              expand: expand.length ? expand.join(',') : undefined,
              code: code
            }
          })
        },

        limits: {
          get (id) {
            return axios.get(`/v1/card/${id}/limits`)
          },
          update (id, data) {
            return axios.patch(`/v1/card/${id}/limits`, data)
          }
        }
      },

      /**
       * Change data usinf code confirmation
       */
      code: {
        /**
         * Send confirmation code to email or phone
         * @param type one of them : {email|phone}
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<any>>}
         */
        send: function (type, data = {}, validate = 1) {
          return axios.post(`/v1/code/${type}/send` , data, {
            params: {
              validate: validate
            }
          })
        },

        /**
         * Confirm code
         * @param type one of them : {email|phone}
         * @param data
         * @param validate
         * @returns {Promise<AxiosResponse<any>>}
         */
        confirm: function (type, data = {}, validate = 1) {
          return axios.post(`/v1/code/${type}`, data, {
            params: {
              validate: validate
            }
          })
        },
      },

      /**
       * Basic site info
       */
      site: {
        info () {
          return axios.get('/v1/info', {
            auth: false
          })
        }
      },

      /**
       * Send list of supported feature-flags
       * @param data
       */
      features (data = []) {
        return axios.post('/v1/features', data)
      }
    }
  }
}
