export default {
  state: {
    /**
     * All data from server
     *
     * @param {Object|null} item
     *
     * @param {Object} item.permissions List of available permissions for company
     * @param {boolean} item.permissions.canClose User can close company
     * @param {boolean} item.is_process_closing Company closing process status
     */
    data: null
  },
  mutations: {
    /**
     * Set company's data
     *
     * @param state
     * @param payload
     */
    'company.set' (state, payload) {
      state.data = payload
    },
  },
  actions: {
    /**
     * Update company data include multiple windows
     *
     * @param commit
     * @param state
     * @returns {PromiseLike<unknown>}
     */
    getCompany ({ commit, state }) {
      return this._vm.$sdk.company.info().then(response => {
        if (response.data) {
          commit('company.set', response.data)
        }

        return response
      })
    }
  },
  getters: {
    companyPermissions: state => state.data.permissions ?? {},
    isProcessClosing: state => state.data.is_process_closing
  }
}

