/**
 *
 * @param {Object} wallet
 * @property {boolean} wallet.is_main     - Is main details
 * @property {boolean} wallet.description - Text
 * @property {boolean} wallet.name        - UID
 *
 * @return {Object} wallet
 * @constructor
 */
export default function WalletDecorator(wallet)
{
  // Universal title
  wallet.title = wallet.description || wallet.name

  // Detect main details
  wallet.mainDetails = wallet.details.find(i => i.is_main)

  // Is CARD wallet type
  wallet.isCardWallet = wallet.type === 'CARD'

  return wallet
}