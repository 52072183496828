import { isEqualInt } from "@/libs/functions";

export default {
  state: {
    /**
     * List of available user's roles
     *
     * @param {object} role
     * @param {string} role.id - unique id of role
     * @param {string} role.label - role label
     */
    roles: [],

    /**
     * List of users
     *
     * @param {object} user
     * @param {number} user.id -
     * @param {number} user.is_active - is active 1 or 0
     * @param {string} user.email - user's email
     * @param {string} user.name - user's name
     * @param {string|null} user.expired_at - unixtime
     * @param {string} user.role - string
     */
    users: []
  },
  mutations: {
    setManagementUsers (state, payload) {
      state.users = payload
    },
    setManagementRoles (state, payload) {
      state.roles = payload
    },
    'management.users.create' (state, payload) {
      state.users.unshift(payload)
    },
    'management.users.push' (state, payload) {
      state.users.push(...payload)
    },
    'management.users.update' (state, payload) {
      const user = state.users.find(item => isEqualInt(item.id, payload.id))
      if (user) {
        for (let field in payload) {
          user[field] = payload[field]
        }
      }
    },
    'management.users.delete' (state, user_id) {
      let index = state.users.findIndex(item => isEqualInt(item.id, user_id))
      state.users.splice(index, 1)
    }
  },
  actions: {
    async getManagementUsers ({ commit }) {
      commit('setManagementUsers', [])

      let mainResponse = await this._vm.$sdk.management.list()
      let pageCount    = parseInt(mainResponse.headers['x-pagination-page-count'])
      let promises     = []

      commit('management.users.push', mainResponse.data)

      for (let page = 2; page <= pageCount; page++) {
        let promise = this._vm.$sdk.management.list({page})
        promises.push(promise)
      }

      let responses = await Promise.all(promises)
      for (let response of responses) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          commit('management.users.push', response.data)
        }
      }
    },
    getManagementRolesList ({ commit, state }) {
      if (state.roles.length > 0) {
        return
      }

      this._vm.$sdk.management.roles().then(response => {
        commit('setManagementRoles', response.data)
      })
    },

    /**
     * Reset management states
     *
     * @param commit
     */
    'management.reset' ({ commit }) {
      commit('setManagementUsers', [])
      commit('setManagementRoles', [])
    },
    'management.create.user' ({ commit, state }, { id }) {
      this._vm.$sdk.management.view(id).then(({ data }) => {
        commit('management.users.create', data)
      })
    },
    'management.update.user' ({ commit, state }, { id }) {
      this._vm.$sdk.management.view(id).then(({ data }) => {
        commit('management.users.update', data)
      })
    },
    'management.delete.user' ({ commit, state }, { id }) {
      commit('management.users.delete', id)
    },
  }
}
