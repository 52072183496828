<template>
  <transition name="fade" mode="out-in">
    <template v-if="available === true">
      <router-view />
    </template>
    <template v-else-if="available === false">
      <technical-break />
    </template>
  </transition>
</template>

<script>
import {mapState} from "vuex";
import TechnicalBreak from "@/layouts/TechnicalBreak";

export default {
  name: 'App',
  components: {
    TechnicalBreak
  },
  computed: {
    ...mapState({
      settings: state => state.site.settings,
      available: state => state.site.available
    })
  },
  beforeCreate() {
    this.$store.dispatch('init')
  },
  metaInfo () {
    let head = {
      title: '',
      meta: [],
      link: [],
      script: []
    }

    if (this.settings.title) {
      head.title = this.settings.title
    }

    if (this.settings.description) {
      head.meta.push({
        name: 'description',
        content: this.settings.description
      })
    }

    if (this.settings.favicon_url) {
      head.link.push({
        rel: 'icon',
        href: this.settings.favicon_url
      })
    }

    if (this.settings.css_url) {
      head.link.push({
        rel: 'stylesheet',
        href: this.settings.css_url
      })
    }

    if (this.settings.js_url) {
      head.script.push({
        src: this.settings.js_url,
        async: true
      })
    }

    return head
  }
}
</script>

<style src="./assets/styles/main.less" lang="less"></style>
