import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import { FEATURES } from '@/store/modules/features'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login/:token',
      component: () => import('../layouts/LoginByToken')
    },
    {
      path: '/login',
      component: () => import('../layouts/Login'),
      name: 'login',
      meta: {
        auth: {
          access: false
        }
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      meta: {
        auth: {
          access: true,
          guard: () => {
            Store.dispatch('auth.logout')
            return Store.getters.isUser
          }
        }
      }
    },
    {
      path: '/logout-timeout',
      name: 'SessionTimeout',
      component: () => import('../components/SessionTimeout'),
      meta: {
        auth: {
          access: false,
          guard: () => {
            Store.dispatch('auth.logout')
            return Store.getters.isUser
          }
        }
      }
    },
    {
      path: '/reset-password',
      component: () => import('../components/ResetPassword'),
      name: 'reset-password',
      meta: {
        auth: {
          access: false
        }
      }
    },
    {
      path: '/reset-password/:token',
      component: () => import('../components/ResetPasswordToken'),
      name: 'reset-password-token',
      meta: {
        auth: {
          access: false
        }
      }
    },
    {
      path: '',
      component: () => import('../layouts/Cabinet'),
      children: [
        {
          path: '/dashboard',
          component: () => import('../components/Dashboard'),
          name: 'Dashboard'
        },
        {
          path: '/wallet',
          component: () => import('../components/Wallet'),
          name: 'Wallets',
        },
        {
          path: '/wallet/:id',
          component: () => import('../components/wallet/View'),
          props: true,
          children: [
            {
              path: 'transactions',
              component: () => import("../components/wallet/payments/WalletPayments"),
              name: 'WalletPayments',
              props: true,
            },
            {
              path: 'cards',
              component: () => import('../components/wallet/cards/Layout'),
              name: 'WalletCards',
              props: true,
            },
            {
              path: '',
              name: 'WalletView',
              redirect: function (from) {
                const wallet = Store.getters.getBalanceById(from.params.id)
                const canSupportCard = Store.getters.canSupport(FEATURES.CARD)
                return (wallet?.isCardWallet && canSupportCard) ? 'cards' : 'transactions'
              }
            }
          ]
        },
        {
          path: '/beneficiary',
          component: () => import('../components/Beneficiary'),
          name: 'Beneficiary'
        },
        {
          path: '/transactions',
          component: () => import('../components/Transaction'),
          name: 'Transactions'
        },
        {
          path: '/settings',
          component: () => import('../components/Settings'),
          children: [
            {
              path: 'personal-data',
              component: () => import('../components/settings/PersonalData'),
              children: [
                {
                  path: '',
                  name: 'PersonalData',
                  redirect: {
                    name: 'Password'
                  }
                },
                {
                  path: 'password',
                  name: 'Password',
                  props: true,
                  component: () => import('../components/settings/personal/ChangePassword.vue')
                },
                {
                  path: ':type',
                  name: 'Data',
                  props: true,
                  component: () => import('../components/settings/personal/ChangeData.vue')
                },
              ],
            },
            {
              path: 'mfa',
              component: () => import('../components/settings/MultiFactorAuth'),
              name: 'Security'
            },
            {
              path: 'notification',
              component: () => import('../components/settings/Notification'),
              name: 'Notifications'
            },
            {
              path: 'multi-users',
              component: () => import('../components/settings/MultUsers'),
              name: 'AccountUsers'
            },
            {
              path: 'delete-data',
              component: () => import('../components/settings/DeleteData'),
              name: 'DeleteData'
            },
            {
              path: '',
              name: 'Settings',
              redirect: {
                name: 'PersonalData'
              }
            }
          ]
        },
        {
          path: '/invest',
          component: () => import('../components/Invest'),
          name: 'Invest'
        }
      ],
      redirect: {
        path: '/dashboard'
      },
      name: 'main',
      meta: {
        auth: {
          access: true
        }
      }
    },
    {
      path: '*',
      redirect: '/dashboard'
    },
  ]
})

export default router
