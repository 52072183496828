import * as Sentry from '@sentry/browser'

export default {
  state: {
    /**
     * All data from server
     *
     * @param {Object|null} item
     *
     * @param {Object} item.permissions List of available permissions for user
     * @param {boolean} item.permissions.canBeneficiaryCreate User can create beneficiaries
     * @param {boolean} item.permissions.canBeneficiaryDelete User can delete beneficiaries
     * @param {boolean} item.permissions.canCurrencyExchangeCreate User can create exchange
     * @param {boolean} item.permissions.canPaymentCancel User can cancel payment
     * @param {boolean} item.permissions.canPaymentCreate User can create payment
     * @param {boolean} item.permissions.canPaymentDraftCreate User can create draft payment
     * @param {boolean} item.permissions.canUserEdit User has management privileges
     * @param {boolean} item.permissions.canUserDelete Can user delete their data
     * @param {boolean} item.permissions.canPaymentSign Can user sign payments
     * @param {boolean} item.permissions.canPaymentDelete Can user delete payments
     * @param {boolean} item.permissions.canCardCreate Can user order card
     * @param {boolean} item.permissions.canCardBlock Can user block card
     * @param {boolean} item.permissions.canCardUnblock Can user unblock card
     * @param {boolean} item.permissions.canCardClose Can user close card
     * @param {boolean} item.permissions.canCardActivate Can user activate card
     * @param {boolean} item.permissions.canCardCvvView Can user view card cvv code
     * @param {boolean} item.permissions.canCardNumberView Can user view card number
     * @param {boolean} item.permissions.canCardPasswordView Can user view card password
     * @param {boolean} item.permissions.canCardPinView Can user view card pin code
     * @param {boolean} item.permissions.canCardPasswordEdit Can user edit card password
     * @param {boolean} item.permissions.canCardPinEdit Can user edit card pin code
     * @param {boolean} item.permissions.canWalletTopUp User can top up wallet from card or with Apple Pay
     * @param {boolean} item.permissions.canWalletEdit Can user edit wallet
     * @param {boolean} item.permissions.canWalletTransfer Can user top up from other wallet
     *
     * @param {Object} item.profile Basic information about account
     * @param {string} item.profile.company Name of company
     * @param {string} item.profile.email Company registration email
     * @param {string} item.profile.phone User's phone
     * @param {string} item.profile.username User's email
     *
     * @param {Object} item.settings Auth settings
     * @param {Boolean} item.settings.mfa_enabled Is MFA enabled
     * @param {String|null} item.settings.mfa_type Type of MFA
     */
    data: null
  },
  mutations: {
    /**
     * Set user's data
     *
     * @param state
     * @param payload
     */
    'user.set' (state, payload) {
      state.data = payload
    },
  },
  actions: {
    /**
     * Reset users data
     *
     * @param commit
     */
    'user.reset' ({ commit }) {
      commit('user.set', null)
    },

    /**
     * Update all users data include multiple windows
     *
     * @param commit
     * @param state
     * @returns {PromiseLike<unknown>}
     */
    getUser ({ commit, state }) {
      return this._vm.$sdk.user.info().then(response => {
        if (response.data.success) {
          commit('user.set', response.data.data)

          Sentry.setUser({
            email: state.data.profile.email,
            username: state.data.profile.company,
          })
        }

        return response
      })
    },
  },
  getters: {
    isMfaEnabled: state => !!state.data?.settings?.mfa_enabled,
    permissions: state => state.data?.permissions ?? {},
    profile: state => state.data?.profile,
    hasUser: state => !!state.data
  }
}
