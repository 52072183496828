<template>
  <div class="tb">

    <logo v-if="settings.logo_file" class="tb-logo"/>

    <div class="tb-image">
      <div class="tb-man">
        <img src="@/assets/images/technical-break/Man.png" alt=""/>
      </div>
      <div class="tb-line" />
    </div>

    <div class="tb-text">
      <h1 class="tb-title">Techno magic</h1>
      <div class="tb-description">Sit back and relax while the magic happens.
        <br> Right now we are making {{ settings.brand_name || 'our project' }} a better place.
        <br> Please come back soon, when we’re done.
        <p v-if="settings.email_support">
          <br>Our contact details: {{ settings.email_support }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Logo from "@/libs/Logo";

export default {
  name: 'TechnicalBreak',
  components: {Logo},
  computed: {
    ...mapState({
      settings: state => state.site.settings
    }),
  }
}
</script>

<style lang="less" scoped>
.tb {
  display: flex;
  flex-direction: column;
  //height: 100vh;
  min-height: 100vh;
  justify-content: center;

  width: 100%;
  text-align: center;

  &-logo {
    margin: 30px auto;
  }

  &-man {
    z-index: 2;
    position: relative;
    left: -250px;
    max-width: 500px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &-line {
    z-index: 1;
    margin-top: -35px;
    position: relative;
    background: url('../assets/images/technical-break/Crossline.svg') center repeat-x;
    width: 100vw;
    height: 50px;
  }

  &-title {
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    text-transform: uppercase;
    //font-family:;
    color: #FF6041;
    font-family: Graphik, serif;
    margin-top: 35px;
    margin-bottom: 10px;
  }

  &-description {
    font-size: 15px;
    line-height: 22.5px;
    margin-bottom: 30px;
  }

  &-buttons {
    margin: 0 20px;
  }

  &-text {
    margin-top: 40px;
    padding: 0 20px;
  }

  @media all and (max-width: 1000px) {
    .tb-image {
      margin-top: 100px;
    }

    .tb-man {
      left: 0;
      margin: 0 40px;
    }

    .tb-title {
      font-size: 25px;
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  @media all and (min-width: 1000px) {
    .tb-logo {
      position: absolute;
      top: 30px;

      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
  }
}
</style>
