import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from "@/router";

export default {
  install: function (Vue) {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      ignoreErrors: [
        'ChunkLoadError',
        'NavigationDuplicated',
        'Request failed with status code 400'
      ]
    })
  }
}
