import { Centrifuge } from 'centrifuge';

let EventEmitter = require('eventemitter3');
const eventBus = new EventEmitter()


export default {
    /**
     *
     * @param Vue
     * @param url
     * @param getToken
     */
    install(Vue, { url, options }) {
        /**
         * Centrifuge client
         * @type {Centrifuge}
         */
        let centrifuge = new Centrifuge(url, options)

        /**
         * @type {Centrifuge}
         */
        Vue.prototype.$centrifuge = centrifuge

        /**
         * Received events
         */
        centrifuge.on('publication', function ({ client, data }) {
            const [event, params] = data
            eventBus.emit(event, params)
        })

        /**
         * Add listeners
         */
        const addListeners = function () {
            let sockets = this.$options.sockets
            if (sockets && typeof sockets === 'object') {
                for (let event in sockets) {
                    if (typeof sockets[event] === 'function') {
                        // console.log('Register: ' + event)
                        eventBus.on(event, sockets[event], this)
                    }
                }
            }
        }

        /**
         * Remove events
         */
        const removeListeners = function () {
            let sockets = this.$options.sockets
            if (sockets && typeof sockets === 'object') {
                for (let event in sockets) {
                    if (typeof sockets[event] === 'function') {
                        // console.log('Unregister: ' + event)
                        eventBus.off(event, sockets[event], this)
                    }
                }
            }
        }

        /**
         * Add mixins
         */
        Vue.mixin({
            mounted: addListeners,
            beforeDestroy: removeListeners
        })
    }
}
