<template>
  <img :src="settings.logo_file" :alt="settings.brand_name" />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Logo',
  computed: {
    ...mapState({
      settings: state => state.site.settings
    })
  }
}
</script>
