export const FEATURES = {
  CARD: 'CARD',
  APPLE_WALLET_CONNECT: 'APPLE_WALLET_CONNECT',
  WALLET_TOP_UP_FROM_CARD: 'WALLET_TOP_UP_FROM_CARD',
  WALLET_TOP_UP_FROM_APPLE: 'WALLET_TOP_UP_FROM_APPLE',
  WALLET_TOP_UP_FROM_KLARNA: 'WALLET_TOP_UP_FROM_KLARNA',
}

export default {
  state: {
    features: []
  },
  mutations: {
    'features.set' (state, payload) {
      state.features = payload
    }
  },
  actions: {
    getFeatures ({ commit }) {
      return this._vm.$sdk.features(Object.values(FEATURES)).then(response => {
        commit('features.set', response.data)
        return response
      })
    }
  },
  getters: {
    canSupport: state => value => {
      return state.features.indexOf(value) !== -1
    },
    hasTopUpFeature: state => {
      return state.features.some(i => i.startsWith('WALLET_TOP_UP_FROM'))
    }
  }
}
