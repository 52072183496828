/**
 * Comparing two values
 *
 * @param val1
 * @param val2
 *
 * @returns {boolean}
 */
export function isEqualInt(val1, val2) {
  return parseInt(val1) === parseInt(val2)
}

/**
 * Comparing to strings
 *
 * @param val1
 * @param val2
 * @returns {boolean}
 */
export function isEqualString (val1, val2) {
  return val1.toLowerCase() === val2.toLowerCase()
}