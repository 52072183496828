import WalletPaymentDecorator from "../../decorators/WalletPaymentDecorator";
import { isEqualInt } from "../../libs/functions";

export default {
  state: {
    /**
     * Drafts count
     */
    draftCount: 0,

    /**
     * List of payments
     * @class Payments
     * @type object[]
     *
     * @property {Object} currency_id
     * @property {Object} currencyExchange
     * @property {number} currencyExchange.rate
     * @property {number} currencyExchange.debitAmount
     * @property {number} currencyExchange.debitCurrencyId
     * @property {number} currencyExchange.creditAmount
     * @property {number} currencyExchange.creditCurrencyId
     */
    payments: [],

    /**
     * Items per page
     */
    perPage: 20,

    /**
     * Params
     */
    filter: {},
    filterDefault: {
      filter: 'WALLET'
    },

    /**
     * Current page
     */
    page: 1,

    /**
     *
     */
    identifier: +new Date()
  },
  mutations: {
    'wallet.draftCount.set' (state, payload) {
      state.draftCount = payload
    },
    'wallet.payments.set' (state, payload) {
      state.payments = payload.map(i => {
        return WalletPaymentDecorator(i, this._vm)
      })
    },
    'wallet.payments.push' (state, payload) {
      if (Array.isArray(payload) && payload.length > 0) {
        let items = payload.map(i => { return WalletPaymentDecorator(i, this._vm) });
        state.payments.push(...items)
      }
    },
    'wallet.filter.set' (state, payload) {
      state.filter = {
        ...state.filterDefault,
        ...payload
      }
    },
    'wallet.page.set' (state, payload) {
      state.page = payload
    },
    'wallet.page.increment' (state) {
      state.page += 1
    },
    'wallet.identifier.increment' (state) {
      state.identifier +=1
    },
    'wallet.perPage.set' (state, payload) {
      state.perPage = parseInt(payload)
    },

    /**
     * Partially update one payment
     *
     * @param payment_id - payment for update
     * @param fields - fields for update
     * @param state
     */
    'wallet.payments.update' (state, {payment_id, fields}) {
      const item = state.payments.find(item => isEqualInt(item.id, payment_id));

      if (item) {
        for(let field in fields) {
          item[field] = fields[field]
        }
      }
    },

    /**
     * Delete one item per list
     *
     * @param state
     * @param payment_id
     */
    'wallet.payments.delete' (state, payment_id) {
      let index = state.payments.findIndex(item => isEqualInt(item.id, payment_id));
      state.payments.splice(index, 1)
    },

    /**
     * Add data into start of array
     * @param state
     * @param {object} data
     */
    'wallet.prepend' (state, data) {
      let index = state.payments.findIndex(i => parseInt(data.id) === parseInt(i.id))
      if (index >= 0) return

      state.payments.unshift(WalletPaymentDecorator(data, this._vm))

      if (state.payments.length > state.perPage) {
        state.payments.splice(state.payments.length - 1, 1)
      }
    }
  },
  actions: {
    /**
     * Update number of draft payments
     *
     * @param commit
     * @param state
     * @return {*}
     */
    'wallet.drafts' ({ commit, state }) {
      let params = {...state.filter}
      params.status = 'draft'

      return this._vm.$sdk.payment.list(params).then(response => {
        commit ('wallet.draftCount.set', parseInt(response.headers['x-pagination-total-count']))
      })
    },

    /**
     * Set form data
     *
     * @param commit
     * @param payload
     */
    'wallet.filter.set' ({ commit }, payload) {
      commit('wallet.filter.set', payload)
    },

    /**
     * Add more records to payments array
     *
     * @param commit
     * @param state
     * @param payload
     */
    'wallet.more' ({ commit, state }) {
      let params = {...state.filter}

      params.page = state.page
      params.sort = ['-createdAtMs','-id'].join(',')

      return this._vm.$sdk.payment.list(params).then(response => {
        let payments = response.data

        commit('wallet.perPage.set', response.headers['x-pagination-per-page'])
        commit('wallet.payments.push', payments)
        commit('wallet.page.increment') // page ++

        return response
      })
    },

    /**
     * Reset payments list
     * @param commit
     */
    'wallet.reset' ({ commit }) {
      commit('wallet.page.set', 1)
      commit('wallet.payments.set', [])
    },

    /**
     * Update exact payment fields
     *
     * @param commit
     * @param state
     * @param payment_id  - Payment id for update
     * @param fields - fields for update
     */
    'wallet.update.payment.fields' ({ commit, state }, {payment_id, fields}) {
      commit('wallet.payments.update', {
        payment_id: payment_id,
        fields: fields
      })
    },


    /**
     * Update all payment fields
     *
     * @param commit
     * @param state
     * @param payment_id  - Payment id for update
     */
    async 'wallet.update.payment.full' ({ commit, state }, { payment_id }) {
      const payment = await this._vm.$sdk.payment.show(payment_id)

      commit('wallet.payments.update', {
        payment_id: payment_id,
        fields: payment.data
      })
    },

    /**
     * Reload list of current payments
     *
     * @param commit
     * @param dispatch
     */
    'wallet.reload' ({ commit, dispatch }) {
      commit('wallet.page.set', 1)
      commit('wallet.payments.set', [])
      commit('wallet.identifier.increment')

      dispatch('wallet.drafts')
    },

    /**
     * Add wallet to the list
     *
     * @param commit
     * @param payment_id
     */
    'wallet.add' ({ commit }, payment_id) {
      this._vm.$sdk.payment.show(payment_id).then(({ data }) => {
        commit('wallet.prepend', data)
      })
    }
  }
}
