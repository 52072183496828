/**
 *
 * @param {Object} payment
 * @return {*}
 * @constructor
 */
export default function WalletPaymentDecorator(payment, vue)
{
  // Added group
  payment.group = vue.$moment(payment.createdAtMs).startOf('day').format('x')

  return payment
}