import FingerprintJS from "@fingerprintjs/fingerprintjs";
import router from "@/router";

export default {
  state: {
    access_token: null,
    refresh_token: null,
    ws_token: null,

    refresh_promise: null
  },
  mutations: {
    'auth.set.access_token' (state, payload) {
      state.access_token = payload
    },
    'auth.set.refresh_token' (state, payload) {
      state.refresh_token = payload
    },
    'auth.set.ws_token' (state, payload) {
      state.ws_token = payload
    },
    'auth.set.tokens' (state, payload) {
      if (payload.access_token) {
        state.access_token = payload.access_token
      }
      if (payload.refresh_token) {
        state.refresh_token = payload.refresh_token
      }
      if (payload.ws_token) {
        state.ws_token = payload.ws_token
      }
    },
    'auth.promise.refresh' (state, payload) {
      state.refresh_promise = payload
    }
  },
  actions: {
    /**
     * Return fingerprint ID
     *
     * @param commit
     * @return {Promise<GetResult>}
     */
    async 'fingerprint.detect' ({ commit }) {
      const fpPromise = FingerprintJS.load()

      const fp = await fpPromise
      const result = await fp.get()

      return result.visitorId ?? null
    },

    /**
     * Process user login
     * In success case we are redirect him to home page
     * at trying to get information about user
     *
     * @param dispatch
     * @param commit
     * @param data
     *
     * @param data.username
     * @param data.password
     * @param data.code
     * @param data.fingerprint
     *
     * @returns {Promise<AxiosResponse<*>>}
     */
    async 'auth.login' ({ dispatch, commit }, data) {
      data.fingerprint = await dispatch('fingerprint.detect')
      return this._vm.$sdk.auth.login(data, false).then(response => {
        commit('auth.set.tokens', response.data)
        return response
      })
    },

    /**
     * Refresh tokens
     *
     * @param commit
     * @param state
     * @param getters
     * @return {Promise<AxiosResponse<*>>}
     */
    'auth.refresh' ({ commit, state, getters }) {
      if (state.refresh_promise) {
        return state.refresh_promise
      }

      let promise = this._vm.$sdk.auth.refresh(state.refresh_token)
        .then((response) => {
          commit('auth.set.tokens', response.data)
          return response
        })
        .catch(reason => {
          let route = getters.hasUser
              ? {name: 'SessionTimeout'}
              : {name: 'Logout'}

          router.push(route)

          return Promise.reject(reason)
        })
        .finally(() => {
          commit('auth.promise.refresh', null)
        })

      commit('auth.promise.refresh', promise)

      return state.refresh_promise
    },

    /**
     * Reset tokens
     * @param commit
     */
    'auth.reset' ({ commit }) {
      commit('auth.set.access_token', null)
      commit('auth.set.refresh_token', null)
      commit('auth.set.ws_token', null)
    },

    /**
     * Logout user and clear state
     *
     * @param commit
     * @param dispatch
     */
    'auth.logout' ({ dispatch }) {
      dispatch('auth.reset')
      dispatch('user.reset')
      dispatch('wallet.reset')
      dispatch('transactions.reset')
      dispatch('management.reset')
      dispatch('notifications.reset')
    },
  },
  getters: {
    isUser: state => !!(state.access_token || state.refresh_token),
    wsToken: state => state.ws_token
  }
}
