export default {
  state: {
    isMenuMainOpened: true,
    isMenuSettingsOpened: false
  },
  mutations: {
    'menu.main.toggle' (state) {
      state.isMenuMainOpened = !state.isMenuMainOpened
    },
    'menu.settings.toggle' (state) {
      state.isMenuSettingsOpened = !state.isMenuSettingsOpened
    }
  },
  actions: {
    toggleMainMenu ({ commit }) {
      commit('menu.main.toggle')
    },
    toggleSettingsMenu ({ commit }) {
      commit('menu.settings.toggle')
    }
  }
}
