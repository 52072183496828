import WalletDecorator from "../../decorators/WalletDecorator";
import { isEqualInt } from "../../libs/functions";

export default {
  state: {
    items: []
  },
  mutations: {
    /**
     * Set balances list
     *
     * @param state
     * @param payload
     */
    'balances.set' (state, payload) {
      state.items = payload
    },

    /**
     * Add wallets to end of list
     *
     * @param state
     * @param payload
     */
    'balances.push' (state, payload) {
      state.items.push(...payload)
    },

    /**
     * Update balance item fields
     *
     * @param state
     * @param wallet_id
     * @param fields
     */
    updateBalance (state, { wallet_id, fields }) {
      let item = state.items.find(i => isEqualInt(i.id, wallet_id))

      if (item) {
        for (let field in fields) {
          if (item[field] !== fields[field]) {
            item[field] = fields[field]
          }
        }
      }
    }
  },
  actions: {
    updateBalance ({ commit }, payload) {
      commit('updateBalance', payload)
    },

    /**
     * Load list of all balances
     *
     * @param commit
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getBalances ({ commit }) {
      commit('balances.set', [])

      // Get only headers of request
      let mainResponse  = await this._vm.$sdk.wallet.list()
      let pageCount     = parseInt(mainResponse.headers['x-pagination-page-count'])
      let promises      = []

      // Set first part of data
      commit('balances.push', mainResponse.data)

      // Collect all promises
      for (let page = 2; page <= pageCount; page++) {
        let promise = this._vm.$sdk.wallet.list({page})
        promises.push(promise)
      }

      // Add all results
      let responses = await Promise.all(promises)
      for (let response of responses) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          commit('balances.push', response.data)
        }
      }
    }
  },
  getters: {
    /**
     * Return full list without filtration
     *
     * @param state
     * @returns {array}
     */
    getAllBalances (state) {
      return state.items.map(i => WalletDecorator(i))
    },

    /**
     * Return non-blocked (active and inactive) balances
     *
     * @param state
     * @param getters
     * @return {*}
     */
    getActiveBalances(state, getters) {
      return getters.getAllBalances.filter(function (item) {
        return item.status === 'ACTIVE'
      })
    },

    /**
     * Return balances only with flag is_active
     *
     * @param state
     * @param getters
     * @return {*}
     */
    getActiveVisibleBalances(state, getters) {
      return getters.getActiveBalances.filter(function (item) {
        return item.isVisible
      })
    },

    /**
     * Return balances only with flag is_inactive
     *
     * @param state
     * @param getters
     * @return {*}
     */
    getActiveHiddenBalances(state, getters) {
      return getters.getActiveBalances.filter(function (item) {
        return !item.isVisible
      })
    },

    /**
     * Return blocked balances
     *
     * @param state
     * @param getters
     * @return {Object[]}
     */
    getBlockedBalances(state, getters) {
      return getters.getAllBalances.filter(function (item) {
        return item.status === 'BLOCKED'
      })
    },

    /**
     * Return item by ID
     *
     * @return {Object}
     * @param state
     * @param getters
     */
    getBalanceById: (state, getters) => id => {
      return getters.getAllBalances.find(i => {
        return Number(i.id) === Number(id)
      })
    }
  }
}
