import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import company from './modules/company'
import balance from './modules/balance'
import transaction from './modules/transaction'
import notifications from './modules/notifications'
import management from './modules/management'
import wallet from './modules/wallet'
import site from './modules/site'
import auth from './modules/auth'
import features from './modules/features'

import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import menu from "@/store/modules/menu";

import lodashGet from 'lodash/get'

Vue.use(Vuex)

const ls = new SecureLS({
  encodingType: 'rc4', // changeable
  isCompression: true,
  encryptionSecret: '#hB)eLXC#n%9R@h6hE', // change this
})

export default new Vuex.Store({
  modules: {
    auth,
    user,
    company,
    balance,
    transaction,
    notifications,
    management,
    wallet,
    site,
    menu,
    features
  },
  plugins: [
    createPersistedState({
      key: 'extData',
      reducer: (Store) => {
        return {
          company_id: lodashGet(Store, 'company.data.id'),
          user_id: lodashGet(Store, 'user.data.profile.id')
        }
      }
    }),
    createPersistedState({
      key: 'data',
      paths: [
        // Auth tokens
        'auth.refresh_token',
        'auth.access_token',
        'auth.ws_token',

        // Menu open/close
        'menu.isMenuMainOpened',
        'menu.isMenuSettingsOpened'
      ],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    })
  ]
})
