export default {
  state: {
    /**
     * @type {object[]} List of transactions from server
     */
    items: [],

    /**
     * @type {number} Index of current page
     */
    page: 1,

    /**
     *
     */
    identifier: +new Date()
  },
  mutations: {
    /**
     * Add items to state
     *
     * @param state
     * @param {[]|{}} payload
     */
    'transactions.push' (state, payload) {
      if (Array.isArray(payload) && payload.length > 0) {
        state.items.push(...payload)
      }
    },

    /**
     * Set items list
     *
     * @param state
     * @param {[]} payload
     */
    'transactions.set' (state, payload) {
      state.items = payload
    },

    /**
     * Update page
     *
     * @param state
     * @param { number } payload
     */
    'transactions.page' (state, payload) {
      state.page = parseInt(payload)
    },

    /**
     *
     * @param state
     */
    'transactions.identifier.increment' (state) {
      state.identifier +=1
    }
  },
  actions: {
    /**
     * Update list of transactions with filter options
     *
     * @param commit
     * @param state
     * @param params
     * @return {*}
     */
    getTransactions ({ commit, state }, params) {
      const defaultParams = {
        page: state.page,
        sort: '-completed_at,-id',
      }

      const data = {
        ...defaultParams,
        ...params
      }

      if (!Object.hasOwnProperty.call(data, 'status')) {
        data.status = 'completed'
      }

      let wallet_id = data.wallet_id
      delete data['wallet_id']

      return this._vm.$sdk.wallet.payments(wallet_id, data)
    },

    /**
     * @param state
     * @param payload
     * @return {boolean}
     */
    'transactions.more' (state, payload) {
      return state.dispatch('getTransactions', payload).then(response => {
        state.commit('transactions.page', state.state.page + 1)
        state.commit('transactions.push', response.data)

        return response
      })
    },

    /**
     * Reset transactions
     * @param state
     */
    'transactions.reset' ({ commit }) {
      commit('transactions.set', [])
      commit('transactions.page', 1)
    },

    /**
     * Reload list of transactions
     *
     * @param commit
     * @param dispatch
     */
    'transactions.reload' ({ dispatch, commit }) {
      commit('transactions.set', [])
      commit('transactions.page', 1)
      commit('transactions.identifier.increment')
    }
  }
}
