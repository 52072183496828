export const TYPE_REQUIRED = 'REQUIRED'
export const TYPE_DEFAULT = 'DEFAULT'
/**
 *
 * @param {Object} notification
 * @property {boolean} notification.type  - Notification type
 * @property {boolean} wallet.viewedAt    - Time in microseconds
 *
 * @return {Object} notification
 * @constructor
 */
export default function NotificationDecorator(notification) {
  notification.isRequired = notification.type === TYPE_REQUIRED
  notification.isViewed = !!notification.viewedAt

  return notification
}
