import NotificationDecorator from "@/decorators/NotificationDecorator";
import orderBy from 'lodash/orderBy'
import {isEqualInt} from "@/libs/functions";

export default {
  state: {
    /**
     * @type {object[]}
     * @property {string} type
     * @property {null|number} createdAt - Time in microseconds
     * @property {null|number} updatedAt - Time in microseconds
     * @property {null|number} viewedAt - Time in microseconds
     * @property {boolean} isViewed
     * @property {boolean} isRequired
     */
    items: []
  },
  mutations: {
    'notifications.set' (state, payload) {
      state.items = payload
    },
    'notification.create' (state, payload) {
      state.items.unshift(NotificationDecorator(payload))
    },
    'notification.update' (state, payload) {
      const updatedNotification = NotificationDecorator(payload)
      const index = state.items.findIndex(i => isEqualInt(i.id, updatedNotification.id))

      if (index > -1) {
        state.items.splice(index, 1, updatedNotification)
      }
    },
    'notification.delete' (state, id) {
      let index = state.items.findIndex(item => isEqualInt(item.id, id))
      if (index > -1) {
        state.items.splice(index, 1)
      }
    },
  },
  actions: {
    /**
     * Create list of sorted notifications
     * In the top of list must be isRequired && !isViewed items
     *
     * @param commit
     * @param payload
     * @returns {Promise<AxiosResponse<T>>}
     */
    getNotifications ({ commit }, payload) {
      return this._vm.$sdk.notifications.list(payload).then(response => {

        const list = response.data.map(i => NotificationDecorator(i))

        const items = orderBy(list, [function (item) {
          return item.isRequired && !item.isViewed
        }, 'isViewed'], ['desc', 'asc'])

        commit('notifications.set', items)
      })
    },

    /**
     * Reset notifications
     * @param commit
     */
    'notifications.reset' ({ commit }) {
      commit('notifications.set', [])
    }
  },
  getters: {
    getViewedNotificationsCount: state => {
      return state.items.filter(item => item.isViewed).length
    },
    getNotViewedNotificationsCount: state => {
      return state.items.filter(item => !item.isViewed).length
    },
    getRequiredNotificationsCount: state => {
      return state.items.filter(item => item.isRequired).length
    },
    getRequiredNotViewedNotifications: state => {
      return state.items.filter(item => item.isRequired && !item.isViewed).length
    }
  }
}
