export default {
  state: {
    /**
     * @param {boolean} available.reg
     * @param {boolean} available.app
     */
    available: null,

    /**
     * @param {Object} setting
     *
     * @param {string} setting.title         Title of project
     * @param {string} setting.description   Project description
     * @param {string} setting.favicon_url   Path to favicon
     * @param {string} setting.brand_name    Brand name
     * @param {string} setting.brand_name    Brand name
     * @param {string} setting.email_info    Email like info@altpay.uk
     * @param {string} setting.email_support Email like support@altpay.uk
     * @param {string} setting.logo_file     Path to logo file
     * @param {string} setting.phone_support Support contact phone number
     * @param {string} setting.css_url       Path to custom css file
     */
    settings: {}
  },
  mutations: {
    'SET_AVAILABLE' (state, payload) {
      state.available = payload
    },
    'SET_SETTINGS' (state, payload) {
      state.settings = payload
    }
  },
  actions: {
    /**
     * For checking first user page loading
     *
     * @param dispatch
     * @param commit
     * @param state
     */
    init ({ dispatch, commit, state }) {
      return dispatch('getSiteInfo')
    },

    /**
     * Get information about site
     *
     * @param commit
     * @return {Promise<AxiosResponse<any>>}
     */
    getSiteInfo ({ commit }) {
      return this._vm.$sdk.site.info().then(response => {
        const data = response.data

        if (data.settings) {
          commit('SET_SETTINGS', data.settings)
        }

        if (data.available) {
          commit('SET_AVAILABLE', data.available.app)
        }

        return response
      }).catch(() => {
        commit('SET_AVAILABLE', false)
      })
    }
  }
}
