import Vue from 'vue'
import App from './App'

import sdk from './libs/sdk'
import router from './router'
import VueRouterAuth from 'vue-router-auth';
import store from './store'
import sentry from './libs/sentry'
import visibility from 'vue-visibility-change';

import moment from 'vue-moment'
import numeralFilter from 'vue-numeral-filter'
import modal from 'vue-js-modal'
import AsyncComputed from 'vue-async-computed'
import VueMask from 'v-mask'
import VueClipboard from 'vue-clipboard2'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VBodyScrollLock from 'v-body-scroll-lock'
import VueNoty from '@marcius-studio/vue-notice'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.directive('tooltip', VTooltip)

Vue.use(VueMeta)
Vue.use(VBodyScrollLock)
Vue.use(VueClipboard)
Vue.use(sentry)
Vue.use(sdk)
Vue.use(VueMask)
Vue.use(AsyncComputed)
Vue.use(moment)
Vue.use(visibility)
Vue.use(numeralFilter)
Vue.use(modal, {
  dynamicDefaults: {
    width: '100%',
    height: '100%'
  },
  adaptive: true
})
Vue.use(VueNoty, {
  layout: 'bottomCenter',
  theme: false,
  progressBar: false,
  timeout: 1500,
  animation: {
    open: 'noty_effects_open',
    close: 'noty_effects_close'
  }
})

Vue.use(VueRouterAuth, {
  router,
  guard: () => store.getters.isUser,
  routes: {
    guest: '/login',
    auth: '/main'
  }
});

import Centrifuge from './plugins/centrifuge'

Vue.use(Centrifuge, {
  url: process.env.VUE_APP_SOCKET_URL,
  options: {
    // debug: true,
    getToken: async () => store.getters.wsToken
  }
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

window.App = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
